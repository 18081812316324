<template>
  <div class="trouble-analysis-wrapper">
    <van-field
      v-model="query.name"
      class="input-pull-down time-unit"
      right-icon="shouqi"
      icon-prefix="iconfont"
      readonly
      placeholder="日"
      @click="popupVisible = true"
    />
    <div id="trouble-num-analysis"></div>
    <select-popup
      v-model="query.timeUnit"
      title="时间"
      :visible="popupVisible"
      :data="timeUnitTypes"
      :popup-style="{ height: '45vh' }"
      @close="popupVisible = false"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import echarts from "echarts/lib/echarts";
require("echarts/lib/chart/line");

import { lineOptions } from "../utils/constant";
import cloneDeep from "lodash/cloneDeep";
import { formatterXAxis } from "@/views/analysis/utils";
import SelectPopup from "@/components/SelectPopup";
import { timeUnitTypes } from "@/views/analysis/utils/constant";

export default {
  name: "AnalysisTroubleNum",
  components: { SelectPopup },
  props: {
    chartData: {
      type: [Object, Array],
      default: () => []
    }
  },
  data() {
    return {
      chartOptions: cloneDeep(lineOptions),
      timeUnitTypes,
      lineChart: null,
      lineEl: null,
      query: {
        timeUnit: "1",
        name: "周"
      },
      popupVisible: false
    };
  },
  watch: {
    chartData: {
      async handler(val) {
        if (val) {
          await this.$nextTick();
          this.processChartData();
          await this.refreshChart();
        }
      },
      deep: true
    }
  },
  created() {},
  async mounted() {
    await this.$nextTick();
    this.initEChart();
  },
  methods: {
    initOptions() {
      const lineSeriesItem = {
        type: "line",
        // stack: "新增数量",
        symbolSize: 10,
        data: [],
        itemStyle: { color: "#7D5FCA" }
      };
      const fixLineSeriesItem = {
        ...lineSeriesItem,
        itemStyle: { color: "#0ED681" }
      };
      //  整改数量折线图
      this.chartOptions.series.push(lineSeriesItem, fixLineSeriesItem);
    },
    initEChart() {
      this.initOptions();
      this.lineEl = document.getElementById("trouble-num-analysis");
      this.lineChart = echarts.init(this.lineEl);
    },
    setOptions(chart, options) {
      chart.clear();
      chart.resize();
      chart.setOption(options);
    },
    processChartData() {
      const fixNumConf = this.chartData;
      // 整改数量是多个
      fixNumConf.riskStateData.forEach((item, idx) => {
        this.chartOptions.series[idx].name = item.name;
        this.chartOptions.series[idx].data = item.data;
      });
      this.chartOptions.xAxis.data = fixNumConf.time;
      this.chartOptions.xAxis.axisLabel.formatter = formatterXAxis;
    },
    async refreshChart() {
      await this.$nextTick();
      this.setOptions(this.lineChart, this.chartOptions);
    },
    onConfirm(row) {
      this.query.timeUnit = row.value;
      this.query.name = row.label;
      this.$emit("time-unit-change", this.query.timeUnit);
    }
  }
};
</script>

<style lang="scss">
.trouble-analysis-wrapper {
  #trouble-num-analysis {
    height: 98vw;
  }
  .time-unit {
    margin-top: 20px;
  }
  .van-cell {
    width: 25vw;
    margin-left: 70vw;
  }
}
</style>
