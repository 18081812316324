<template>
  <div class="trouble-analysis-wrapper">
    <div v-show="chartData.length" id="trouble-status-analysis"></div>
    <van-empty v-show="!chartData.length" description="暂无数据" />
  </div>
</template>

<script>
import echarts from "echarts/lib/echarts";
require("echarts/lib/chart/pie");

import { pieOptions, statusColorsConf, statusColors } from "../utils/constant";
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "AnalysisTroubleStatus",
  props: {
    chartData: {
      type: [Object, Array],
      default: () => []
    }
  },
  data() {
    return {
      chartOptions: cloneDeep(pieOptions),
      pieChart: null,
      pieEl: null
    };
  },
  watch: {
    chartData: {
      async handler(val) {
        if (val) {
          console.log(val, "val");
          await this.$nextTick();
          this.processChartData();
          await this.refreshChart();
        }
      },
      deep: true
    }
  },
  created() {},
  async mounted() {
    await this.$nextTick();
    this.initEChart();
  },
  methods: {
    initOptions() {
      // 状态饼图
      this.chartOptions.color = statusColors;
      this.chartOptions.series.data = [];
      this.chartOptions.series.name = "隐患状态";
    },
    initEChart() {
      this.initOptions();
      this.pieEl = document.getElementById("trouble-status-analysis");
      this.pieChart = echarts.init(this.pieEl);
    },
    setOptions(chart, options) {
      chart.clear();
      chart.resize();
      chart.setOption(options);
    },
    processChartData() {
      this.chartOptions.series.data =
        this.chartData &&
        this.chartData.map(item => {
          if (statusColorsConf[item.type]) {
            item = {
              ...item,
              itemStyle: {
                color: statusColorsConf[item.type].color
              }
            };
          }
          return item;
        });
    },
    async refreshChart() {
      await this.$nextTick();
      this.setOptions(this.pieChart, this.chartOptions);
    }
  }
};
</script>

<style lang="scss">
.trouble-analysis-wrapper {
  #trouble-status-analysis {
    height: 96vw;
  }
}
</style>
