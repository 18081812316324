import { deepFreeze } from "@/utils";
export const timeUnitTypes = deepFreeze([
  {
    label: "日",
    value: "0"
  },
  {
    label: "周",
    value: "1"
  },
  {
    label: "月",
    value: "2"
  },
  {
    label: "年",
    value: "3"
  }
]);

export const statusColors = [
  "#a8a8fc",
  "#ffb556",
  "#ff9cc2",
  "#46e962",
  "#7de3ff",
  "#acff61",
  "#8c7ad7"
];

export const levelColors = [
  "#FF7955",
  "#FFAF48",
  "#FEDC71",
  "#F9F68D",
  "#A3CEFF",
  "#74ABFD"
];
export const statusColorsConf = {
  0: { color: "#a8a8fc" },
  1: { color: "#ffb556" },
  2: { color: "#ff9cc2" },
  3: { color: "#46e962" },
  4: { color: "#7de3ff" },
  5: { color: "#acff61" },
  7: { color: "#8c7ad7" }
};

export const levelColorsConf = {
  1: { color: "#FF7955", otherColor: "#FDAC91" },
  2: { color: "#FFAF48", otherColor: "" },
  3: { color: "#FEDC71", otherColor: "" },
  4: { color: "#F9F68D", otherColor: "" },
  5: { color: "#A3CEFF", otherColor: "" },
  6: { color: "#74ABFD", otherColor: "" }
};

export const pieOptions = {
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c} ({d}%)"
  },
  color: [],
  legend: {
    // type: "scroll",
    orient: "horizontal",
    left: "center",
    top: "94%"
  },
  series: {
    name: "",
    type: "pie",
    radius: "55%",
    center: ["50%", "53%"],
    label: {
      fontSize: 12,
      color: "#595F66",
      formatter: `{b}：{c}`
    },
    data: [],
    itemStyle: {
      emphasis: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: "rgba(0, 0, 0, 0.5)"
      }
    }
  }
};

export const lineOptions = {
  tooltip: {
    trigger: "axis",
    padding: 14
  },
  legend: {
    itemGap: 40,
    icon: "rect",
    show: true,
    top: "90%",
    textStyle: {
      color: "#AEB0BD"
    }
  },
  grid: {
    left: "10%",
    right: "10%",
    bottom: "19%",
    containLabel: true
  },
  toolbox: {},
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
    axisLine: {
      show: true,
      lineStyle: {
        color: "#C0C4CC",
        width: 1
      }
    },
    axisLabel: {
      color: "#595F66",
      rotate: 45,
      fontSize: 12
    },
    axisTick: {
      show: false
    }
  },
  yAxis: {
    type: "value",
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#C0C4CC",
        width: 1
      }
    },
    axisLabel: {
      color: "#595F66"
    },
    splitLine: {
      lineStyle: {
        color: ["#E8E9EB"],
        type: "dashed"
      }
    }
  },
  series: []
};

export const lineSeriesItem = {
  type: "line",
  symbolSize: 10,
  name: "",
  data: [],
  lineStyle: { width: 2.5 }
};
