<template>
  <div class="trouble-analysis">
    <van-nav-bar
      title="改善统计"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="trouble-analysis-container">
      <van-field
        v-model="queryInfo.departmentName"
        left-icon="shouqi"
        readonly
        icon-prefix="iconfont"
        placeholder="所属部门"
        class="select-department"
        @click="departmentVisible = true"
      />
      <date-time v-model="dateTime" type="date" :time-close="false" />
      <van-tabs v-model="tabId" swipeable @change="onTabChange">
        <van-tab title="改善状态">
          <analysis-trouble-status
            ref="trouble-status"
            :chart-data="chartDataForStatus"
          />
        </van-tab>
        <van-tab title="改善级别">
          <analyze-trouble-level
            ref="trouble-level"
            :chart-data="chartDataForLevel"
          />
        </van-tab>
        <van-tab title="整改数量">
          <analysis-trouble-num
            ref="trouble-num"
            :chart-data="chartDataForFixNum"
            @time-unit-change="onSearch"
          />
        </van-tab>
        <van-tab title="完成率">
          <analysis-trouble-finish-ratio
            ref="trouble-finish-ratio"
            :chart-data="chartDataForFinishRatio"
            @time-unit-change="onSearch"
          />
        </van-tab>
        <van-tab title="及时率">
          <analysis-trouble-timely-ratio
            ref="trouble-timely-ratio"
            :chart-data="chartDataForTimelyRatio"
            @time-unit-change="onSearch"
          />
        </van-tab>
      </van-tabs>
    </div>
    <cascade-department
      v-model="department"
      :visible="departmentVisible"
      include-children
      @close="departmentVisible = false"
      @confirm="onTreeConfirm"
    />
  </div>
</template>

<script>
import CascadeDepartment from "@/components/CascadeDepartment";
import { mapState } from "vuex";
import { parseTime } from "@/utils";
import { ONE_DAY_MS } from "@/utils/constant";
import AnalysisTroubleStatus from "@/views/analysis/components/AnalysisTroubleStatus";
import {
  getStatisticsTroubleFixNum,
  getStatisticsTroubleLevel,
  getStatisticsTroubleRatios,
  getStatisticsTroubleStatus
} from "@/api/improve/improve";
import AnalyzeTroubleLevel from "@/views/analysis/components/AnalyzeTroubleLevel";
import AnalysisTroubleNum from "@/views/analysis/components/AnalysisTroubleNum";
import AnalysisTroubleFinishRatio from "@/views/analysis/components/AnalysisTroubleFinishRatio";
import AnalysisTroubleTimelyRatio from "@/views/analysis/components/AnalysisTroubleTimelyRatio";

require("echarts/lib/chart/effectScatter"); // 拐点出闪烁，高亮
require("echarts/lib/component/tooltip"); // 提示组件
require("echarts/lib/component/legend");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");

const refsMap = {
  0: "trouble-status",
  1: "trouble-level",
  2: "trouble-num",
  3: "trouble-finish-ratio",
  4: "trouble-timely-ratio"
};

export default {
  name: "TroubleAnalysis",
  components: {
    AnalysisTroubleTimelyRatio,
    AnalysisTroubleFinishRatio,
    AnalysisTroubleNum,
    AnalyzeTroubleLevel,
    AnalysisTroubleStatus,
    CascadeDepartment
  },
  data() {
    return {
      department: "",
      queryInfo: {
        orgCode: "",
        department: "",
        departmentName: "",
        subDepartment: true,
        timeUnit: "",
        startTime: "",
        endTime: ""
      },
      dateTime: {
        start: "",
        end: ""
      },
      departmentVisible: false,
      tabId: 0,
      chartDataForStatus: [],
      chartDataForLevel: [],
      chartDataForFixNum: [],
      chartDataForFinishRatio: [],
      chartDataForTimelyRatio: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  watch: {
    dateTime: {
      async handler(val) {
        await this.$nextTick();
        if (val.start && val.end) {
          // this.queryInfo.startTime = val.start + " 00:00:00";
          // this.queryInfo.endTime = val.end + " 23:59:59";
          this.queryInfo.startTime = val.start;
          this.queryInfo.endTime = val.end;
          this.onSearch();
        }
        if (!val.start && !val.end) {
          this.queryInfo.startTime = "";
          this.queryInfo.endTime = "";
          this.onSearch();
        }
      },
      deep: true
    }
  },
  created() {
    this.queryInfo.orgCode = this.userInfo.orgCode;
    this.dateTime = {
      start: parseTime(Date.now() - ONE_DAY_MS * 7, "{y}-{m}-{d}"),
      end: parseTime(Date.now(), "{y}-{m}-{d}")
    };
  },
  methods: {
    onTreeConfirm(selectValue, includeInfo) {
      this.queryInfo.department = this.department;
      this.queryInfo.subDepartment = includeInfo?.include;
      this.queryInfo.departmentName = selectValue?.label || "";
      this.onSearch();
    },
    onTabChange(tabId) {
      this.refreshChart(tabId);
    },
    async refreshChart(tabId) {
      tabId = tabId || this.tabId;
      await this.$nextTick();
      this.onSearch();
      const ref = refsMap[tabId];
      await this.$refs[ref].refreshChart();
    },
    onSearch() {
      const searchMap = {
        0: this.getStatus,
        1: this.getLevel,
        2: this.getFixNum,
        3: this.getFinishRatio,
        4: this.getTimelyRatio
      };
      searchMap[this.tabId]();
    },
    async getStatus() {
      try {
        this.chartDataForStatus = await getStatisticsTroubleStatus(
          this.queryInfo
        );
      } catch (e) {
        console.log(e);
      }
    },
    async getLevel() {
      try {
        this.chartDataForLevel = await getStatisticsTroubleLevel(
          this.queryInfo
        );
      } catch (e) {
        console.log(e);
      }
    },
    async getQuery() {
      await this.$nextTick();
      const query = { ...this.queryInfo };
      // query.department = this.queryInfo.department.join(",");
      const ref = refsMap[this.tabId];
      query.timeUnit = this.$refs[ref].query.timeUnit;
      return query;
    },
    async getFixNum() {
      const query = await this.getQuery();
      try {
        this.chartDataForFixNum = await getStatisticsTroubleFixNum(query);
      } catch (e) {
        console.log(e);
      }
    },
    async getFinishRatio() {
      const query = await this.getQuery();
      try {
        this.chartDataForFinishRatio = await getStatisticsTroubleRatios(
          query,
          0
        );
      } catch (e) {
        console.log(e);
      }
    },
    async getTimelyRatio() {
      const query = await this.getQuery();
      try {
        this.chartDataForTimelyRatio = await getStatisticsTroubleRatios(
          query,
          1
        );
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss">
.trouble-analysis-container {
  .select-department.van-cell {
    width: inherit;
    margin: 12px 16px;
    border: 1px solid #c7c9d0;
    border-radius: 2px;
    padding-top: 8px;
    padding-left: 12px;
    padding-bottom: 7px;
    font-size: 14px;
    line-height: 1;
    .van-field__value {
      line-height: 20px;
    }
    ::-webkit-input-placeholder {
      color: #2e2e4d;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #2e2e4d;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #2e2e4d;
    }
    :-ms-input-placeholder {
      color: #2e2e4d;
    }
    .van-field__left-icon {
      line-height: 20px;
      .iconfont {
        color: #aeb3c0;
        font-size: 12px;
      }
    }
  }
  .datetime {
    border: 1px solid #c7c9d0;
    .input-style {
      color: #2e2e4d;
    }
  }
}
</style>
